
import * as React from "react"
import { HeadFC, } from "gatsby"

import Layout from "../components/layout"

import Seo from "../components/seo"

const Contact = () => {
  return (
    <Layout>

      <h1>Contact us</h1>
      <form data-static-form-name="contact" method="POST">
        <label>Email address <input type="email" name="email" /></label>
        <label>Message <textarea name="message"></textarea></label>
        <input type="hidden" name="static-form-name" value="contact"></input>
        <button type="submit">Submit</button>
      </form>

    </Layout>
  )
}

export const Head: HeadFC = () => (
  <Seo title='Contact' />
)

export default Contact
